import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import img from "../../assets/imgs/page/about/img.png";
import staft1 from "../../assets/imgs/page/about/staft1.png";
import staft2 from "../../assets/imgs/page/about/staft2.png";
import staft3 from "../../assets/imgs/page/about/staft3.png";
import staft4 from "../../assets/imgs/page/about/staft4.png";
import staft5 from "../../assets/imgs/page/about/staft5.png";
import staft6 from "../../assets/imgs/page/about/staft6.png";
import staft7 from "../../assets/imgs/page/about/staft7.png";
import staft8 from "../../assets/imgs/page/about/staft8.png";
import microsoft from "../../assets/imgs/page/about/microsoft.svg";
import sony from "../../assets/imgs/page/about/sony.svg";
import acer from "../../assets/imgs/page/about/acer.svg";
import nokia from "../../assets/imgs/page/about/nokia.svg";
import asus from "../../assets/imgs/page/about/asus.svg";
import casio from "../../assets/imgs/page/about/casio.svg";
import dell from "../../assets/imgs/page/about/dell.svg";
import panasonic from "../../assets/imgs/page/about/panasonic.svg";
import vaio from "../../assets/imgs/page/about/vaio.svg";
import sharp from "../../assets/imgs/page/about/sharp.svg";
import chat from "../../assets/imgs/page/contact/chat.svg";
import call from "../../assets/imgs/page/contact/call.svg";
import map from "../../assets/imgs/page/contact/map.svg";
import delivery from "../../assets/imgs/template/delivery.svg";
import support from "../../assets/imgs/template/support.svg";
import voucher from "../../assets/imgs/template/voucher.svg";
import returned from "../../assets/imgs/template/return.svg";
import secure from "../../assets/imgs/template/secure.svg";

function About(){
    return(
        <>
            <Header/>
            <Sidebar/>      
            <div class="section-box">
        <div class="breadcrumbs-div">
          <Container>
            <ul class="breadcrumb">
              <li><a class="font-xs color-gray-1000" href="index.html">Home</a></li>
              <li><a class="font-xs color-gray-500" href="index.html">Pages</a></li>
              <li><a class="font-xs color-gray-500" href="page-about-us.html">About Us</a></li>
            </ul>
          </Container>
        </div>
      </div>
      <section class="section-box shop-template mt-30">
        <Container>
          <Row>
            <Col lg={12} class="mx-auto">
              <h5 class="color-gray-500 mb-10">About us</h5>
              <h2>Global Leading Online Shop</h2>
              <Row class="mt-20">
                <Col lg={6}>
                  <p class="font-sm font-medium color-gray-700 mb-15">Ecom expands global footprint, delivering entertaining experiences customised to each user. Branches are expanding both in size and number.</p>
                  <p class="font-sm font-medium color-gray-700 mb-15">Nunc vel risus commodo viverra maecenas accumsan lacus vel facilisis. Lacus vel facilisis volutpat est velit. Urna neque viverra justo nec. Suspendisse faucibus interdum posuere lorem ipsum dolor. Ut aliquam purus sit amet luctus venenatis lectus magna fringilla. Tortor vitae purus faucibus ornare.</p>
                  <p class="font-sm font-medium color-gray-700 mb-15">Tempus iaculis urna id volutpat lacus laoreet. Id neque aliquam vestibulum morbi blandit. Lacinia quis vel eros donec ac odio tempor orci. Lectus sit amet est placerat in egestas erat imperdiet. Nunc congue nisi vitae suscipit. Sed adipiscing diam donec adipiscing tristique risus.</p>
                  <ul class="list-services mt-20">
                    <li class="hover-up">We provide qualified & expert</li>
                    <li class="hover-up">Modern tools & technology use</li>
                    <li class="hover-up">Neat & cleaning top Services</li>
                    <li class="hover-up">We Develop Digital Future</li>
                  </ul>
                </Col>
                <Col lg={6}><img src={img} alt="Ecom"/></Col>
              </Row>
              <div class="box-contact-support pt-80 pb-50 pl-50 pr-50 background-gray-50 mt-50 mb-90">
                <Row>
                  <Col lg={3} class="mb-30 text-center text-lg-start">
                    <h4 class="mb-5">12 Years</h4>
                    <p class="font-md color-gray-700">We’ve more than 12 years working experience.</p>
                  </Col>
                  <Col lg={3} class="mb-30 text-center text-lg-start">
                    <h4 class="mb-5">2000+ Employee</h4>
                    <p class="font-md color-gray-700 mb-5">We’ve more than 2000 employees working near you.</p>
                  </Col>
                  <Col lg={3} class="mb-30 text-center text-lg-start">
                    <h4 class="mb-5">68 Branches</h4>
                    <p class="font-md color-gray-700 mb-5">We have 68 branches across the country and are expanding</p>
                  </Col>
                  <Col lg={3} class="mb-30 text-center text-lg-start">
                    <h4 class="mb-5">15 Countries</h4>
                    <p class="font-md color-gray-700 mb-5">We are present in 15 countries around the world</p>
                  </Col>
                </Row>
              </div>
              <h5 class="color-gray-500 mb-10">Behind The Brands</h5>
              <h2 class="mb-40">The people who work at Ecom share the vision and values of our community.</h2>
              <Row class="mb-50">
                <Col lg={3} md={4} sm={6}>
                  <div class="card-staff hover-up">
                    <div class="image-staff"><img src={staft1} alt="Ecom"/></div>
                    <div class="info-staff">
                      <h5>Ronald Richards</h5>
                      <p class="font-md color-gray-500">Chief Executive Officer</p>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div class="card-staff hover-up">
                    <div class="image-staff"><img src={staft2} alt="Ecom"/></div>
                    <div class="info-staff">
                      <h5>Jenny Wilson</h5>
                      <p class="font-md color-gray-500">Chief Financial Officer</p>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div class="card-staff hover-up">
                    <div class="image-staff"><img src={staft3} alt="Ecom"/></div>
                    <div class="info-staff">
                      <h5>Cody Fisher</h5>
                      <p class="font-md color-gray-500">Chief Operating Officer</p>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div class="card-staff hover-up">
                    <div class="image-staff"><img src={staft4} alt="Ecom"/></div>
                    <div class="info-staff">
                      <h5>Jacob Jones</h5>
                      <p class="font-md color-gray-500">Chief Technology Officer</p>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div class="card-staff hover-up">
                    <div class="image-staff"><img src={staft5} alt="Ecom"/></div>
                    <div class="info-staff">
                      <h5>Leslie Alexander</h5>
                      <p class="font-md color-gray-500">Chief Marketing Officer</p>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div class="card-staff hover-up">
                    <div class="image-staff"><img src={staft6} alt="Ecom"/></div>
                    <div class="info-staff">
                      <h5>Ralph Edwards</h5>
                      <p class="font-md color-gray-500">Chief Human Resources Officer</p>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div class="card-staff hover-up">
                    <div class="image-staff"><img src={staft7} alt="Ecom"/></div>
                    <div class="info-staff">
                      <h5>Eleanor Pena</h5>
                      <p class="font-md color-gray-500">Chief Legal Officer</p>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div class="card-staff hover-up">
                    <div class="image-staff"><img src={staft8} alt="Ecom"/></div>
                    <div class="info-staff">
                      <h5>Theresa Webb</h5>
                      <p class="font-md color-gray-500">Chief Product Officer</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <h5 class="color-gray-500 mb-10">Our Partners</h5>
              <h2 class="mb-40">Trusted by 18.000+ Vendors</h2>
              <div class="box-images-logo"><a class="hover-up" href="shop-vendor-single.html"><img src={microsoft} alt="Ecom"/></a><a class="hover-up" href="shop-vendor-single.html"><img src={sony} alt="Ecom"/></a><a class="hover-up" href="shop-vendor-single.html"><img src={acer} alt="Ecom"/></a><a class="hover-up" href="shop-vendor-single.html"><img src={nokia} alt="Ecom"/></a><a class="hover-up" href="shop-vendor-single.html"><img src={asus} alt="Ecom"/></a><a class="hover-up" href="shop-vendor-single.html"><img src={casio} alt="Ecom"/></a><a class="hover-up" href="shop-vendor-single.html"><img src={dell} alt="Ecom"/></a><a class="hover-up" href="shop-vendor-single.html"><img src={panasonic} alt="Ecom"/></a><a class="hover-up" href="shop-vendor-single.html"><img src={vaio} alt="Ecom"/></a><a class="hover-up" href="shop-vendor-single.html"><img src={sharp} alt="Ecom"/></a></div>
              <div class="border-1 mb-80 mt-50"></div>
              <h2 class="mb-5">Visit our stores</h2>
              <p class="font-sm color-gray-700">Find us at these locations</p>
              <div class="box-contact-address pt-30 pb-50">
                <Row>
                  <Col lg={4}>
                    <div class="mb-30">
                      <h4>Melbourne</h4>
                      <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                    </div>
                    <div class="mb-30">
                      <h4>San Francisco</h4>
                      <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                    </div>
                    <div class="mb-30">
                      <h4>Byron Bay</h4>
                      <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div class="mb-30">
                      <h4>Sydney</h4>
                      <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                    </div>
                    <div class="mb-30">
                      <h4>Sweden</h4>
                      <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                    </div>
                    <div class="mb-30">
                      <h4>Ha Noi</h4>
                      <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div class="mb-30">
                      <h4>Bangkok</h4>
                      <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                    </div>
                    <div class="mb-30">
                      <h4>Seoul</h4>
                      <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                    </div>
                    <div class="mb-30">
                      <h4>Paris</h4>
                      <p class="font-sm color-gray-700">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <div class="box-contact-support pt-80 pb-50 background-gray-50">
          <Container>
            <Row>
              <Col lg={1}></Col>
              <Col lg={10}>
                <Row>
                  <Col lg={3} class="mb-30 text-center text-lg-start">
                    <h3 class="mb-5">We‘d love to here from you</h3>
                    <p class="font-sm color-gray-700">Chat with our friendly team</p>
                  </Col>
                  <Col lg={3} class="text-center mb-30">
                    <div class="box-image mb-20"><img src={chat} alt="Ecom"/></div>
                    <h4 class="mb-5">Chat to sales</h4>
                    <p class="font-sm color-gray-700 mb-5">Speak to our team.</p><a class="font-sm color-gray-900" href="mailto:sales@ecom.com">sales@ecom.com</a>
                  </Col>
                  <Col lg={3} class="text-center mb-30">
                    <div class="box-image mb-20"><img src={call} alt="Ecom"/></div>
                    <h4 class="mb-5">Call us</h4>
                    <p class="font-sm color-gray-700 mb-5">Mon-Fri from 8am to 5pm</p><a class="font-sm color-gray-900" href="tel:+1(555)000-0000">+1(555)000-0000</a>
                  </Col>
                  <Col lg={3} class="text-center mb-30">
                    <div class="box-image mb-20"><img src={map} alt="Ecom"/></div>
                    <h4 class="mb-5">Visit us</h4>
                    <p class="font-sm color-gray-700 mb-5">Visit our office</p><span class="font-sm color-gray-900">205 North Michigan Avenue, Suite 810<br/>Chicago, 60601, USA</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section class="section-box mt-90 mb-50">
        <Container>
          <ul class="list-col-5">
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={delivery} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Free Delivery</h5>
                  <p class="font-sm color-gray-500">From all orders over $10</p>
                </div>
              </div>
            </li>
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={support} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Support 24/7</h5>
                  <p class="font-sm color-gray-500">Shop with an expert</p>
                </div>
              </div>
            </li>
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={voucher} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Gift voucher</h5>
                  <p class="font-sm color-gray-500">Refer a friend</p>
                </div>
              </div>
            </li>
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={returned} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Return &amp; Refund</h5>
                  <p class="font-sm color-gray-500">Free return over $200</p>
                </div>
              </div>
            </li>
            <li>
              <div class="item-list">
                <div class="icon-left"><img src={secure} alt="Ecom"/></div>
                <div class="info-right">
                  <h5 class="font-lg-bold color-gray-100">Secure payment</h5>
                  <p class="font-sm color-gray-500">100% Protected</p>
                </div>
              </div>
            </li>
          </ul>
        </Container>
      </section>
      <section class="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6} md={7} sm={12}>
              <h3 class="color-white">Subscrible &amp; Get <span class="color-warning">10%</span> Discount</h3>
              <p class="font-lg color-white">Get E-mail updates about our latest shop and <span class="font-lg-bold">special offers.</span></p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div class="box-form-newsletter mt-15">
                <form class="form-newsletter">
                  <input class="input-newsletter font-xs" value="" placeholder="Your email Address"/>
                  <button class="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
            <Footer/>
        </>
    );
}
export default About;