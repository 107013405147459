import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/layouts/Home';
import Productdetails from './components/layouts/Productdetails';
import Shoplist from './components/layouts/Shoplist';
import Shopcheckout from './components/layouts/Shopcheckout';
import "../src/assets/css/style.css";
import Shopcart from './components/layouts/Shopcart';
import Pageregister from './components/Pageregister';
import Pagelogin from './components/layouts/Pagelogin';
import About from './components/layouts/About';
import Contact from './components/layouts/Contact';
import Myaccount from './components/layouts/Myaccount';
import Shopwishlist from './components/layouts/Shopwishlist';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element= {<Home/>}></Route>
        <Route path='/productdetails' element={<Productdetails/>}></Route>
        <Route path='/shoplist' element={<Shoplist/>}></Route>
        <Route path='/shopcheckout' element={<Shopcheckout/>}></Route>
        <Route path='/shopcart' element={<Shopcart/>}></Route>
        <Route path='/pageregister' element={<Pageregister/>}></Route>
        <Route path='/pagelogin' element={<Pagelogin/>}></Route>
        <Route path='/about' element={<About/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/myaccount' element={<Myaccount/>}></Route>
        <Route path='/shopwishlist' element={<Shopwishlist/>}></Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
