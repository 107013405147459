import { Col, Container, Row } from "react-bootstrap";
import {Swiper as SwiperComponent} from "swiper/react";
import { SwiperSlide } from "swiper/react";
import star from "../../assets/imgs/template/icons/star.svg";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import banner from "../../assets/imgs/page/shop/grid-2/banner.png";
import catimg1 from "../../assets/imgs/page/homepage2/cat-img-1.png";
import catimg2 from "../../assets/imgs/page/homepage2/cat-img-2.png";
import catimg3 from "../../assets/imgs/page/homepage2/cat-img-3.png";
import catimg6 from "../../assets/imgs/page/homepage2/cat-img-6.png";
import catimg7 from "../../assets/imgs/page/homepage2/cat-img-7.png";
import catimg8 from "../../assets/imgs/page/homepage2/cat-img-8.png";
import catimg9 from "../../assets/imgs/page/homepage2/cat-img-9.png";
import catimg10 from "../../assets/imgs/page/homepage2/cat-img-10.png";
import catimg16 from "../../assets/imgs/page/homepage2/cat-img-16.png";
import imgsp1 from "../../assets/imgs/page/homepage1/imgsp1.png";
import imgsp2 from "../../assets/imgs/page/homepage1/imgsp2.png";
import imgsp3 from "../../assets/imgs/page/homepage1/imgsp3.png";
import imgsp4 from "../../assets/imgs/page/homepage1/imgsp4.png";
import imgsp5 from "../../assets/imgs/page/homepage1/imgsp5.png";
import imgsp6 from "../../assets/imgs/page/homepage1/imgsp6.png";
import imgsp7 from "../../assets/imgs/page/homepage1/imgsp7.png";
import camera from "../../assets/imgs/page/homepage2/camera.png";
import clock from "../../assets/imgs/page/homepage2/clock.png";
import airpod from "../../assets/imgs/page/homepage2/airpod.png";
import delivery from "../../assets/imgs/template/delivery.svg";
import support from "../../assets/imgs/template/support.svg";
import voucher from "../../assets/imgs/template/voucher.svg";
import returned from "../../assets/imgs/template/return.svg";
import secure from "../../assets/imgs/template/secure.svg";
import stargray from "../../assets/imgs/template/icons/star-gray.svg";

function Shoplist(){
    return(
        <>
            
    <Header/>
      <Sidebar/>
  <div className="section-box">
    <div className="breadcrumbs-div">
      <Container>
        <ul className="breadcrumb">
          <li><a className="font-xs color-gray-1000" href="index.html">Home</a></li>
          <li><a className="font-xs color-gray-500" href="shop-grid-2.html">Electronics</a></li>
          <li><a className="font-xs color-gray-500" href="shop-grid-2.html">Cell phone</a></li>
          <li><a className="font-xs color-gray-500" href="shop-grid-2.html">Accessories</a></li>
        </ul>
      </Container>
    </div>
  </div>
  <div className="section-box shop-template mt-30">
    <Container>
      <SwiperComponent className="slider-shop-2">
        <div className="swiper-container swiper-group-3">
          <div className="swiper-wrapper pt-5">
            <SwiperSlide>
              <div className="card-grid-style-2"><span className="label bg-brand-2">-12%</span>
                <div className="image-box"><a href="shop-single-product.html"><img src={catimg6} alt="Ecom"/></a></div>
                <div className="info-right"><span className="font-xs color-gray-500">YSSOA Store</span><br/><a className="color-brand-3 font-sm-bold" href="shop-single-product.html">Chair Mesh Ergonomic Adjustable Swivel Task Chair with Headrest</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500">(65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2556.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-grid-style-2"><span className="label bg-brand-2">-17%</span>
                <div className="image-box"><a href="shop-single-product.html"><img src={catimg9} alt="Ecom"/></a></div>
                <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a className="color-brand-3 font-sm-bold" href="shop-single-product.html">Straight Talk Samsung Galaxy A03s, 32GB, Black</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500">(65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2556.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-grid-style-2"><span className="label bg-brand-2">-20%</span>
                <div className="image-box"><a href="shop-single-product.html"><img src={catimg10} alt="Ecom"/></a></div>
                <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a className="color-brand-3 font-sm-bold" href="shop-single-product.html">iMac MNE92LL/A 27 Inch, 3.4 GHz Intel Core i5, 8GB RAM, 1TB Drive</a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500">(65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2556.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-grid-style-2"><span className="label bg-brand-2">-22%</span>
                <div className="image-box"><a href="shop-single-product.html"><img src={catimg16} alt="Ecom"/></a></div>
                <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a className="color-brand-3 font-sm-bold" href="shop-single-product.html">Apple Watch Series 8 [GPS 45mm] Smart Watch w/ Midnight Case </a>
                  <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500">(65)</span></div>
                  <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2556.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                </div>
              </div>
            </SwiperSlide>
          </div>
        </div>
        <div className="swiper-button-next swiper-button-next-group-3"></div>
        <div className="swiper-button-prev swiper-button-next-group-3"></div>
      </SwiperComponent>
    </Container>
  </div>
  <section className="section-box shop-template mt-30">
    <Container>
      <Row>
        <Col lg={3}>
          <div className="sidebar-ads">
            <div className="bg-electronic"><span className="big-deal mb-5">Big deal</span>
              <h4 className="font-25">Electronic</h4>
              <p className="font-16 color-brand-3">Hot devices, Latest trending</p>
            </div>
          </div>
        </Col>
        <Col lg={9}>
          <div className="banner-top-gray-100">
            <div className="banner-ads-top mb-30"><a href="#"><img src={banner} alt="Ecom"/></a></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={9} className="order-first order-lg-last">
          <div className="box-filters mt-0 pb-5 border-bottom">
            <Row>
              <Col xl={2} lg={3} className="mb-10 text-lg-start text-center"><a className="btn btn-filter font-sm color-brand-3 font-medium" href="#ModalFiltersForm" data-bs-toggle="modal">All Fillters</a></Col>
              <Col xl={10} lg={9} className="mb-10 text-lg-end text-center"><span className="font-sm color-gray-900 font-medium border-1-right span">Showing 1&ndash;16 of 17 results</span>
                <div className="d-inline-block"><span className="font-sm color-gray-500 font-medium">Sort by:</span>
                  <div className="dropdown dropdown-sort border-1-right">
                    <button className="btn dropdown-toggle font-sm color-gray-900 font-medium" id="dropdownSort" type="button" data-bs-toggle="dropdown" aria-expanded="false">Latest products</button>
                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                      <li><a className="dropdown-item active" href="#">Latest products</a></li>
                      <li><a className="dropdown-item" href="#">Oldest products</a></li>
                      <li><a className="dropdown-item" href="#">Comments products</a></li>
                    </ul>
                  </div>
                </div>
                <div className="d-inline-block"><span className="font-sm color-gray-500 font-medium">Show</span>
                  <div className="dropdown dropdown-sort border-1-right">
                    <button className="btn dropdown-toggle font-sm color-gray-900 font-medium" id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>30 items</span></button>
                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                      <li><a className="dropdown-item active" href="#">30 items</a></li>
                      <li><a className="dropdown-item" href="#">50 items</a></li>
                      <li><a className="dropdown-item" href="#">100 items</a></li>
                    </ul>
                  </div>
                </div>
                <div className="d-inline-block"><a className="view-type-grid mr-5 active" href="shop-grid.html"></a><a className="view-type-list" href="shop-list.html"></a></div>
              </Col>
            </Row>
          </div>
          <Row className="mt-20 display-list">
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp3} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">2022 Apple iMac with Retina 5K Display 8GB RAM Tower PC</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp4} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">Bose Sport Earbuds True Wireless Bluetooth Headphones</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp5} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">2022 Apple 10.9-inch iPad Air Wi-Fi 64GB - Silver</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp6} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">Chromecast with Google TV - Streaming Entertainment</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp7} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">HDR Smart Portable Projector - SP-LSP3BLAXZA 2022</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp1} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">Lenovo Legion 5i 15.6&quot; Laptop, Intel Core i5-10500H</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp2} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">SAMSUNG Galaxy Tab A7 Lite, 8.7&quot; Tablet 32GB</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp3} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">Kasa Outdoor Smart Plug, Smart Home Wi-Fi Outlet with 2 Sockets</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp4} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">Sceptre 24&quot; Professional Thin 75Hz 1080p LED Monitor 2x HDMI</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp5} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">Seagate Portable 2TB External Hard Drive Portable HDD – USB 3.0</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp6} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">SanDisk 128GB Ultra microSDXC UHS-I Memory Card with Adapter </h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp7} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">Original HP 63XL Black High-yield Ink Cartridge | Works</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp1} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">HP 24mh FHD Monitor - Computer Monitor with 23.8-Inch</h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card-grid-style-3">
                <div className="card-grid-inner">
                  <div className="tools"><a className="btn btn-trend btn-tooltip mb-10" href="#" aria-label="Trend"></a><a className="btn btn-wishlist btn-tooltip mb-10" href="shop-wishlist.html" aria-label="Add To Wishlist"></a><a className="btn btn-compare btn-tooltip mb-10" href="shop-compare.html" aria-label="Compare"></a><a className="btn btn-quickview btn-tooltip" aria-label="Quick view" href="#ModalQuickview" data-bs-toggle="modal"></a></div>
                  <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product.html"><img src={imgsp2} alt="Ecom"/></a></div>
                  <div className="info-right"><span className="font-xs color-gray-500">Apple</span><br/><a href="shop-single-product.html">
                      <h4 className="color-brand-3">Logitech MK345 Wireless Combo Full-Sized Keyboard with Palm </h4></a>
                    <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                    <div className="price-info"><strong className="font-lg-bold color-brand-3 price-main">$2856.3</strong><span className="color-gray-500 price-line">$3225.6</span></div>
                    <ul className="list-features">
                      <li> 27-inch (diagonal) Retina 5K display</li>
                      <li>3.1GHz 6-core 10th-generation Intel Core i5</li>
                      <li>AMD Radeon Pro 5300 graphics</li>
                    </ul>
                    <div className="mt-20"><a className="btn btn-cart" href="shop-cart.html">Add To Cart</a></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <nav>
            <ul className="pagination">
              <li className="page-item"><a className="page-link page-prev" href="#"></a></li>
              <li className="page-item"><a className="page-link" href="#">1</a></li>
              <li className="page-item"><a className="page-link active" href="#">2</a></li>
              <li className="page-item"><a className="page-link" href="#">3</a></li>
              <li className="page-item"><a className="page-link" href="#">4</a></li>
              <li className="page-item"><a className="page-link" href="#">5</a></li>
              <li className="page-item"><a className="page-link" href="#">6</a></li>
              <li className="page-item"><a className="page-link page-next" href="#"></a></li>
            </ul>
          </nav>
        </Col>
        <Col lg={3} className="order-last order-lg-first">
          <div className="sidebar-border mb-0">
            <div className="sidebar-head">
              <h6 className="color-gray-900">Product Categories</h6>
            </div>
            <div className="sidebar-content">
              <ul className="list-nav-arrow">
                <li><a href="shop-grid.html">Computers &amp; Laptop<span className="number">09</span></a></li>
                <li><a href="shop-grid.html">Electric accessories<span className="number">12</span></a></li>
                <li><a href="shop-grid.html">Mainboard &amp; CPU<span className="number">24</span></a></li>
                <li><a href="shop-grid.html">Bluetooth devices<span className="number">34</span></a></li>
                <li><a href="shop-grid.html">Mouse &amp; Keyboard<span className="number">65</span></a></li>
                <li><a href="shop-grid.html">Wired Headphone<span className="number">15</span></a></li>
                <li><a href="shop-grid.html">Gaming Gatgets<span className="number">76</span></a></li>
                <li><a href="shop-grid.html">Smart watches<span className="number">89</span></a></li>
                <li><a href="shop-grid.html">Cell Phones<span className="number">23</span></a></li>
                <li><a href="shop-grid.html">Headphone<span className="number">98</span></a></li>
              </ul>
              <div>
                <div className="collapse" id="moreMenu">
                  <ul className="list-nav-arrow">
                    <li><a href="shop-grid.html">Home theater<span className="number">98</span></a></li>
                    <li><a href="shop-grid.html">Cameras & drones<span className="number">124</span></a></li>
                    <li><a href="shop-grid.html">PC gaming<span className="number">56</span></a></li>
                    <li><a href="shop-grid.html">Smart home<span className="number">87</span></a></li>
                    <li><a href="shop-grid.html">Networking<span className="number">36</span></a></li>
                  </ul>
                </div><a className="link-see-more mt-5" data-bs-toggle="collapse" href="#moreMenu" role="button" aria-expanded="false" aria-controls="moreMenu">See More</a>
              </div>
            </div>
          </div>
          <div className="sidebar-border mb-40">
            <div className="sidebar-head">
              <h6 className="color-gray-900">Products Filter</h6>
            </div>
            <div className="sidebar-content">
              <h6 className="color-gray-900 mt-10 mb-10">Price</h6>
              <div className="box-slider-range mt-20 mb-15">
                <div className="row mb-20">
                  <Col sm={12}>
                    <div id="slider-range"></div>
                  </Col>
                </div>
                <Row>
                  <Col lg={12}>
                    <label className="lb-slider font-sm color-gray-500">Price Range:</label><span className="min-value-money font-sm color-gray-1000"></span>
                    <label className="lb-slider font-sm font-medium color-gray-1000"></label>-
                    <span className="max-value-money font-sm font-medium color-gray-1000"></span>
                  </Col>
                  <Col lg={12}>
                    <input className="form-control min-value" type="hidden" name="min-value" value=""/>
                    <input className="form-control max-value" type="hidden" name="max-value" value=""/>
                  </Col>
                </Row>
              </div>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox" checked="checked"/><span className="text-small">Free - $100</span><span className="checkmark"></span>
                  </label><span className="number-item">145</span>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">$100 - $200</span><span className="checkmark"></span>
                  </label><span className="number-item">56</span>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">$200 - $400</span><span className="checkmark"></span>
                  </label><span className="number-item">23</span>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">$400 - $600</span><span className="checkmark"></span>
                  </label><span className="number-item">43</span>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">$600 - $800</span><span className="checkmark"></span>
                  </label><span className="number-item">65</span>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Over $1000</span><span className="checkmark"></span>
                  </label><span className="number-item">56</span>
                </li>
              </ul>
              <h6 className="color-gray-900 mt-20 mb-10">Brands</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox" checked="checked"/><span className="text-small">Apple</span><span className="checkmark"></span>
                  </label><span className="number-item">12</span>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Sony</span><span className="checkmark"></span>
                  </label><span className="number-item">34</span>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Toshiba</span><span className="checkmark"></span>
                  </label><span className="number-item">56</span>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Assus</span><span className="checkmark"></span>
                  </label><span className="number-item">78</span>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Samsung</span><span className="checkmark"></span>
                  </label><span className="number-item">23</span>
                </li>
              </ul>
              <h6 className="color-gray-900 mt-20 mb-10">Color</h6>
              <ul className="list-color">
                <li><a className="color-red active" href="#"></a><span>Red</span></li>
                <li><a className="color-green" href="#"></a><span>Green</span></li>
                <li><a className="color-blue" href="#"></a><span>Blue</span></li>
                <li><a className="color-purple" href="#"></a><span>Purple</span></li>
                <li><a className="color-black" href="#"></a><span>Black</span></li>
                <li><a className="color-gray" href="#"></a><span>Gray</span></li>
                <li><a className="color-pink" href="#"></a><span>Pink</span></li>
                <li><a className="color-brown" href="#"></a><span>Brown</span></li>
                <li><a className="color-yellow" href="#"></a><span>Yellow</span></li>
              </ul><a className="btn btn-filter font-sm color-brand-3 font-medium mt-10" href="#ModalFiltersForm" data-bs-toggle="modal">More Fillters</a>
            </div>
          </div>
          <div className="box-slider-item mb-30">
            <div className="head pb-15 border-brand-2">
              <h5 className="color-gray-900">Best seller</h5>
            </div>
            <div className="content-slider">
              <SwiperComponent className="slide-shop">
                <div className="swiper-container swiper-best-seller">
                  <div className="swiper-wrapper pt-5">
                    <SwiperSlide>
                      <div className="card-grid-style-2 card-grid-none-border border-bottom mb-10">
                        <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product-3.html"><img src={camera} alt="Ecom"/></a>
                        </div>
                        <div className="info-right"><a className="color-brand-3 font-xs-bold" href="shop-single-product-3.html">HP Slim Desktop, Intel Celeron J4025, 4GB RAM</a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                          <div className="price-info"><strong className="font-md-bold color-brand-3 price-main">$150</strong><span className="color-gray-500 font-sm price-line">$187</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-grid-style-2 card-grid-none-border border-bottom mb-10">
                        <div className="image-box"><a href="shop-single-product-3.html"><img src={clock} alt="Ecom"/></a>
                        </div>
                        <div className="info-right"><a className="color-brand-3 font-xs-bold" href="shop-single-product-3.html">className 4K UHD (2160P) LED Roku Smart TV HDR</a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                          <div className="price-info"><strong className="font-md-bold color-brand-3 price-main">$2900</strong><span className="color-gray-500 font-sm price-line">$3200</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-grid-style-2 card-grid-none-border border-bottom mb-10">
                        <div className="image-box"><a href="shop-single-product-3.html"><img src={airpod} alt="Ecom"/></a>
                        </div>
                        <div className="info-right"><a className="color-brand-3 font-xs-bold" href="shop-single-product-3.html">HP 11.6&quot; Chromebook, AMD A4, 4GB RAM, 32GB Storage</a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                          <div className="price-info"><strong className="font-md-bold color-brand-3 price-main">$160</strong><span className="color-gray-500 font-sm price-line">$168</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-grid-style-2 card-grid-none-border border-bottom mb-10">
                        <div className="image-box"><a href="shop-single-product-3.html"><img src={catimg7} alt="Ecom"/></a>
                        </div>
                        <div className="info-right"><a className="color-brand-3 font-xs-bold" href="shop-single-product-3.html">LG 65&quot; className 4K UHD Smart TV OLED A1 Series</a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                          <div className="price-info"><strong className="font-md-bold color-brand-3 price-main">$325</strong><span className="color-gray-500 font-sm price-line">$392</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="card-grid-style-2 card-grid-none-border border-bottom mb-10">
                        <div className="image-box"><a href="shop-single-product-3.html"><img src={catimg8} alt="Ecom"/></a>
                        </div>
                        <div className="info-right"><a className="color-brand-3 font-xs-bold" href="shop-single-product-3.html">Lenovo Legion 5i 15.6&quot; Laptop, Intel Core i5</a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                          <div className="price-info"><strong className="font-md-bold color-brand-3 price-main">$150</strong><span className="color-gray-500 font-sm price-line">$187</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-grid-style-2 card-grid-none-border border-bottom mb-10">
                        <div className="image-box"><span className="label bg-brand-2">-17%</span><a href="shop-single-product-3.html"><img src={catimg1} alt="Ecom"/></a>
                        </div>
                        <div className="info-right"><a className="color-brand-3 font-xs-bold" href="shop-single-product-3.html">SAMSUNG Galaxy Tab A7 Lite, 8.7&quot; Tablet 32GB</a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                          <div className="price-info"><strong className="font-md-bold color-brand-3 price-main">$2900</strong><span className="color-gray-500 font-sm price-line">$3200</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-grid-style-2 card-grid-none-border border-bottom mb-10">
                        <div className="image-box"><a href="shop-single-product-3.html"><img src={catimg2} alt="Ecom"/></a>
                        </div>
                        <div className="info-right"><a className="color-brand-3 font-xs-bold" href="shop-single-product-3.html">Apple AirPods Pro with MagSafe Charging</a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                          <div className="price-info"><strong className="font-md-bold color-brand-3 price-main">$160</strong><span className="color-gray-500 font-sm price-line">$168</span>
                          </div>
                        </div>
                      </div>
                      <div className="card-grid-style-2 card-grid-none-border border-bottom mb-10">
                        <div className="image-box"><a href="shop-single-product-3.html"><img src={catimg3} alt="Ecom"/></a>
                        </div>
                        <div className="info-right"><a className="color-brand-3 font-xs-bold" href="shop-single-product-3.html">Razer Power Up Gaming Bundle V2 - Cynosa</a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                          <div className="price-info"><strong className="font-md-bold color-brand-3 price-main">$325</strong><span className="color-gray-500 font-sm price-line">$392</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </div>
                </div>
                <div className="swiper-button-next swiper-button-next-style-2 swiper-button-next-bestseller"></div>
                <div className="swiper-button-prev swiper-button-prev-style-2 swiper-button-prev-bestseller"></div>
              </SwiperComponent>
            </div>
          </div>
          <div className="box-slider-item">
            <div className="head pb-15 border-brand-2">
              <h5 className="color-gray-900">Product Tags</h5>
            </div>
            <div className="content-slider mb-50"><a className="btn btn-border mr-5" href="shop-grid.html">Games</a><a className="btn btn-border mr-5" href="shop-grid.html">Electronics</a><a className="btn btn-border mr-5" href="shop-grid.html">Video</a><a className="btn btn-border mr-5" href="shop-grid.html">Cellphone</a><a className="btn btn-border mr-5" href="shop-grid.html">Indoor</a><a className="btn btn-border mr-5" href="shop-grid.html">VGA Card</a><a className="btn btn-border mr-5" href="shop-grid.html">USB</a><a className="btn btn-border mr-5" href="shop-grid.html">Lightning</a><a className="btn btn-border mr-5" href="shop-grid.html">Camera</a><a className="btn btn-border" href="shop-grid.html">Window</a><a className="btn btn-border mr-5" href="shop-grid.html">Air Vent</a><a className="btn btn-border mr-5" href="shop-grid.html">Bedroom</a><a className="btn btn-border mr-5" href="shop-grid.html">Laptop</a><a className="btn btn-border mr-5" href="shop-grid.html">Dashboard</a><a className="btn btn-border mr-5" href="shop-grid.html">Keyboard</a></div>
          </div>
          <div className="banner-right h-500 text-center mb-30"><span className="text-no font-11">No.9</span>
            <h5 className="font-23 mt-20">Sensitive Touch<br className="d-none d-lg-block"/>without fingerprint</h5>
            <p className="text-desc font-16 mt-15">Smooth handle and accurate click</p><a href="shop-single-product-2.html">View Details</a>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
  <section className="section-box mt-90 mb-50">
    <Container>
      <ul className="list-col-5">
        <li>
          <div className="item-list">
            <div className="icon-left"><img src={delivery} alt="Ecom"/></div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Free Delivery</h5>
              <p className="font-sm color-gray-500">From all orders over $10</p>
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left"><img src={support} alt="Ecom"/></div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
              <p className="font-sm color-gray-500">Shop with an expert</p>
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left"><img src={voucher} alt="Ecom"/></div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
              <p className="font-sm color-gray-500">Refer a friend</p>
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left"><img src={returned} alt="Ecom"/></div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Return &amp; Refund</h5>
              <p className="font-sm color-gray-500">Free return over $200</p>
            </div>
          </div>
        </li>
        <li>
          <div className="item-list">
            <div className="icon-left"><img src={secure} alt="Ecom"/></div>
            <div className="info-right">
              <h5 className="font-lg-bold color-gray-100">Secure payment</h5>
              <p className="font-sm color-gray-500">100% Protected</p>
            </div>
          </div>
        </li>
      </ul>
    </Container>
  </section>
  <section className="section-box box-newsletter">
    <Container>
      <Row>
        <Col lg={6} md={7} sm={12}>
          <h3 className="color-white">Subscrible &amp; Get <span className="color-warning">10%</span> Discount</h3>
          <p className="font-lg color-white">Get E-mail updates about our latest shop and <span className="font-lg-bold">special offers.</span></p>
        </Col>
        <Col lg={4} md={5} sm={12}>
          <div className="box-form-newsletter mt-15">
            <form className="form-newsletter">
              <input className="input-newsletter font-xs" value="" placeholder="Your email Address"/>
              <button className="btn btn-brand-2">Sign Up</button>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
  <div className="modal fade" id="ModalFiltersForm" tabindex="-1" aria-hidden="true">
    <div className="modal-dialog modal-xl">
      <div className="modal-content apply-job-form">
        <div className="modal-header">
          <h5 className="modal-title color-gray-1000 filters-icon">Addvance Fillters</h5>
          <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body p-30">
          <Row>
            <div className="col-w-1">
              <h6 className="color-gray-900 mb-0">Brands</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox" checked="checked"/><span className="text-small">Apple</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Samsung</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Baseus</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Remax</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Handtown</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Elecom</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Razer</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Auto Focus</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Nillkin</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Logitech</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">ChromeBook</span><span className="checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="col-w-1">
              <h6 className="color-gray-900 mb-0">Special offers</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">On sale</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" checked="checked"/><span className="text-small">FREE shipping</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Big deals</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Shop Mall</span><span className="checkmark"></span>
                  </label>
                </li>
              </ul>
              <h6 className="color-gray-900 mb-0 mt-40">Ready to ship in</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">1 business day</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" checked="checked"/><span className="text-small">1&ndash;3 business days</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">in 1 week</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Shipping now</span><span className="checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="col-w-1">
              <h6 className="color-gray-900 mb-0">Ordering options</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Accepts gift cards</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Customizable</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" checked="checked"/><span className="text-small">Can be gift-wrapped</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Installment 0%</span><span className="checkmark"></span>
                  </label>
                </li>
              </ul>
              <h6 className="color-gray-900 mb-0 mt-40">Rating</h6>
              <ul className="list-checkbox">
                <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(5 stars)</span></a></li>
                <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={stargray} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(4 stars)</span></a></li>
                <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(3 stars)</span></a></li>
                <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(2 stars)</span></a></li>
                <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(1 star)</span></a></li>
              </ul>
            </div>
            <div className="col-w-2">
              <h6 className="color-gray-900 mb-0">Material</h6>
              <ul className="list-checkbox">
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Nylon (8)</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Tempered Glass (5)</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox" checked="checked"/><span className="text-small">Liquid Silicone Rubber (5)</span><span className="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label className="cb-container">
                    <input type="checkbox"/><span className="text-small">Aluminium Alloy (3)</span><span className="checkmark"></span>
                  </label>
                </li>
              </ul>
              <h6 className="color-gray-900 mb-20 mt-40">Product tags</h6>
              <div><a className="btn btn-border mr-5" href="#">Games</a><a className="btn btn-border mr-5" href="#">Electronics</a><a className="btn btn-border mr-5" href="#">Video</a><a className="btn btn-border mr-5" href="#">Cellphone</a><a className="btn btn-border mr-5" href="#">Indoor</a><a className="btn btn-border mr-5" href="#">VGA Card</a><a className="btn btn-border mr-5" href="#">USB</a><a className="btn btn-border mr-5" href="#">Lightning</a><a className="btn btn-border mr-5" href="#">Camera</a></div>
            </div>
          </Row>
        </div>
        <div className="modal-footer justify-content-start pl-30"><a className="btn btn-buy w-auto" href="#">Apply Fillter</a><a className="btn font-sm-bold color-gray-500" href="#">Reset Fillter</a></div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="ModalQuickview" tabindex="-1" aria-hidden="true">
    <div className="modal-dialog modal-xl">
      <div className="modal-content apply-job-form">
        <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        <div className="modal-body p-30">
          <Row>
            <Col lg={6}>
              <div className="gallery-image">
                <div className="galleries-2">
                  <div className="detail-gallery">
                    <div className="product-image-slider-2">
                      <figure className="border-radius-10"><img src="assets/imgs/page/product/img-gallery-1.jpg" alt="product image"/></figure>
                      <figure className="border-radius-10"><img src="assets/imgs/page/product/img-gallery-2.jpg" alt="product image"/></figure>
                      <figure className="border-radius-10"><img src="assets/imgs/page/product/img-gallery-3.jpg" alt="product image"/></figure>
                      <figure className="border-radius-10"><img src="assets/imgs/page/product/img-gallery-4.jpg" alt="product image"/></figure>
                      <figure className="border-radius-10"><img src="assets/imgs/page/product/img-gallery-5.jpg" alt="product image"/></figure>
                      <figure className="border-radius-10"><img src="assets/imgs/page/product/img-gallery-6.jpg" alt="product image"/></figure>
                      <figure className="border-radius-10"><img src="assets/imgs/page/product/img-gallery-7.jpg" alt="product image"/></figure>
                    </div>
                  </div>
                  <div className="slider-nav-thumbnails-2">
                    <div>
                      <div className="item-thumb"><img src="assets/imgs/page/product/img-gallery-1.jpg" alt="product image"/></div>
                    </div>
                    <div>
                      <div className="item-thumb"><img src="assets/imgs/page/product/img-gallery-2.jpg" alt="product image"/></div>
                    </div>
                    <div>
                      <div className="item-thumb"><img src="assets/imgs/page/product/img-gallery-3.jpg" alt="product image"/></div>
                    </div>
                    <div>
                      <div className="item-thumb"><img src="assets/imgs/page/product/img-gallery-4.jpg" alt="product image"/></div>
                    </div>
                    <div>
                      <div className="item-thumb"><img src="assets/imgs/page/product/img-gallery-5.jpg" alt="product image"/></div>
                    </div>
                    <div>
                      <div className="item-thumb"><img src="assets/imgs/page/product/img-gallery-6.jpg" alt="product image"/></div>
                    </div>
                    <div>
                      <div className="item-thumb"><img src="assets/imgs/page/product/img-gallery-7.jpg" alt="product image"/></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-tags">
                <div className="d-inline-block mr-25"><span className="font-sm font-medium color-gray-900">Category:</span><a className="link" href="#">Smartphones</a></div>
                <div className="d-inline-block"><span className="font-sm font-medium color-gray-900">Tags:</span><a className="link" href="#">Blue</a>,<a className="link" href="#">Smartphone</a></div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="product-info">
                <h5 className="mb-15">SAMSUNG Galaxy S22 Ultra, 8K Camera & Video, Brightest Display Screen, S Pen Pro</h5>
                <div className="info-by"><span className="bytext color-gray-500 font-xs font-medium">by</span><a className="byAUthor color-gray-900 font-xs font-medium" href="shop-vendor-list.html"> Ecom Tech</a>
                  <div className="rating d-inline-block"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500 font-medium"> (65 reviews)</span></div>
                </div>
                <div className="border-bottom pt-10 mb-20"></div>
                <div className="box-product-price">
                  <h3 className="color-brand-3 price-main d-inline-block mr-10">$2856.3</h3><span className="color-gray-500 price-line font-xl line-througt">$3225.6</span>
                </div>
                <div className="product-description mt-10 color-gray-900">
                  <ul className="list-dot">
                    <li>8k super steady video</li>
                    <li>Nightography plus portait mode</li>
                    <li>50mp photo resolution plus bright display</li>
                    <li>Adaptive color contrast</li>
                    <li>premium design & craftmanship</li>
                    <li>Long lasting battery plus fast charging</li>
                  </ul>
                </div>
                <div className="box-product-color mt-10">
                  <p className="font-sm color-gray-900">Color:<span className="color-brand-2 nameColor">Pink Gold</span></p>
                  <ul className="list-colors">
                    <li className="disabled"><img src="assets/imgs/page/product/img-gallery-1.jpg" alt="Ecom" title="Pink"/></li>
                    <li><img src="assets/imgs/page/product/img-gallery-2.jpg" alt="Ecom" title="Gold"/></li>
                    <li><img src="assets/imgs/page/product/img-gallery-3.jpg" alt="Ecom" title="Pink Gold"/></li>
                    <li><img src="assets/imgs/page/product/img-gallery-4.jpg" alt="Ecom" title="Silver"/></li>
                    <li className="active"><img src="assets/imgs/page/product/img-gallery-5.jpg" alt="Ecom" title="Pink Gold"/></li>
                    <li className="disabled"><img src="assets/imgs/page/product/img-gallery-6.jpg" alt="Ecom" title="Black"/></li>
                    <li className="disabled"><img src="assets/imgs/page/product/img-gallery-7.jpg" alt="Ecom" title="Red"/></li>
                  </ul>
                </div>
                <div className="box-product-style-size mt-10">
                  <Row>
                    <Col lg={12} className="mb-10">
                      <p className="font-sm color-gray-900">Style:<span className="color-brand-2 nameStyle">S22</span></p>
                      <ul className="list-styles">
                        <li className="disabled" title="S22 Ultra">S22 Ultra</li>
                        <li className="active" title="S22">S22</li>
                        <li title="S22 + Standing Cover">S22 + Standing Cover</li>
                      </ul>
                    </Col>
                    <Col lg={12} className="mb-10">
                      <p className="font-sm color-gray-900">Size:<span className="color-brand-2 nameSize">512GB</span></p>
                      <ul className="list-sizes">
                        <li className="disabled" title="1GB">1GB</li>
                        <li className="active" title="512 GB">512 GB</li>
                        <li title="256 GB">256 GB</li>
                        <li title="128 GB">128 GB</li>
                        <li className="disabled" title="64GB">64GB</li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className="buy-product mt-5">
                  <p className="font-sm mb-10">Quantity</p>
                  <div className="box-quantity">
                    <div className="input-quantity">
                      <input className="font-xl color-brand-3" type="text" value="1"/><span className="minus-cart"></span><span className="plus-cart"></span>
                    </div>
                    <div className="button-buy"><a className="btn btn-cart" href="shop-cart.html">Add to cart</a><a className="btn btn-buy" href="shop-checkout.html">Buy now</a></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
        </>
    );
}
export default Shoplist;