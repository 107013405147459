import { Col, Container, Row } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import google from "../../src/assets/imgs/page/account/google.svg";
import amazon from "../../src/assets/imgs/page/account/amazon.svg";

function Pageregister(){
    return(
        <>
        <Header/>
        <Sidebar/>
      <section className="section-box shop-template mt-60">
        <Container>
          <Row className="mb-100">
            <Col lg={1}></Col>
            <Col lg={5}>
              <h3>Create an account</h3>
              <p className="font-md color-gray-500">Access to all features. No credit card required.</p>
              <div className="form-register mt-30 mb-30">
                <div className="form-group">
                  <label className="mb-5 font-sm color-gray-700">Full Name *</label>
                  <input className="form-control" type="text" placeholder="Steven job"/>
                </div>
                <div className="form-group">
                  <label className="mb-5 font-sm color-gray-700">Email *</label>
                  <input className="form-control" type="text" placeholder="stevenjob@gmail.com"/>
                </div>
                <div className="form-group">
                  <label className="mb-5 font-sm color-gray-700">Username *</label>
                  <input className="form-control" type="text" placeholder="stevenjob"/>
                </div>
                <div className="form-group">
                  <label className="mb-5 font-sm color-gray-700">Password *</label>
                  <input className="form-control" type="password" placeholder="******************"/>
                </div>
                <div className="form-group">
                  <label className="mb-5 font-sm color-gray-700">Re-Password *</label>
                  <input className="form-control" type="password" placeholder="******************"/>
                </div>
                <div className="form-group">
                  <label>
                    <input className="checkagree" type="checkbox"/>By clicking Register button, you agree our terms and policy,
                  </label>
                </div>
                <div className="form-group">
                  <input className="font-md-bold btn btn-buy" type="submit" value="Sign Up"/>
                </div>
                <div className="mt-20"><span className="font-xs color-gray-500 font-medium">Already have an account?</span><a className="font-xs color-brand-3 font-medium" href="page-login.html"> Sign In</a></div>
              </div>
            </Col>
            <Col lg={5}>
              <div className="box-login-social pt-65 pl-50">
                <h5 className="text-center">Use Social Network Account</h5>
                <div className="box-button-login mt-25"><a className="btn btn-login font-md-bold color-brand-3 mb-15">Sign up with<img src={google} alt="Ecom"/></a><a className="btn btn-login font-md-bold color-brand-3 mb-15">Sign up with<span className="color-blue font-md-bold">Facebook</span></a><a className="btn btn-login font-md-bold color-brand-3 mb-15">Sign up with<img src={amazon} alt="Ecom"/></a></div>
                <div className="mt-10 text-center"><span className="font-xs color-gray-900">Buying for work?</span><a className="color-brand-1 font-xs" href="#">Create a free business account</a></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6} md={7} sm={12}>
              <h3 className="color-white">Subscrible &amp; Get <span className="color-warning">10%</span> Discount</h3>
              <p className="font-lg color-white">Get E-mail updates about our latest shop and <span className="font-lg-bold">special offers.</span></p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div className="box-form-newsletter mt-15">
                <form className="form-newsletter">
                  <input className="input-newsletter font-xs" value="" placeholder="Your email Address"/>
                  <button className="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer/>
        </>
    );
}
export default Pageregister;