import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

function Pagelogin(){
    return(
        <>
            <Header/>
            <Sidebar/>
      <section className="section-box shop-template mt-60">
        <Container>
          <Row className="mb-100">
            <Col lg={1}></Col>
            <Col lg={5}>
              <h3>Member Login</h3>
              <p className="font-md color-gray-500">Welcome back!</p>
              <div className="form-register mt-30 mb-30">
                <div className="form-group">
                  <label className="mb-5 font-sm color-gray-700">Email / Phone / Username *</label>
                  <input className="form-control" type="text" placeholder="stevenjob@gmail.com"/>
                </div>
                <div className="form-group">
                  <label className="mb-5 font-sm color-gray-700">Password *</label>
                  <input className="form-control" type="password" placeholder="******************"/>
                </div>
                <Row>
                  <Col lg={6}>
                    <div className="form-group">
                      <label className="color-gray-500 font-xs">
                        <input className="checkagree" type="checkbox"/>Remember me
                      </label>
                    </div>
                  </Col>
                  <Col lg={6} className="text-end">
                    <div className="form-group"><a className="font-xs color-gray-500" href="#">Forgot your password?</a></div>
                  </Col>
                </Row>
                <div className="form-group">
                  <input className="font-md-bold btn btn-buy" type="submit" value="Sign Up"/>
                </div>
                <div className="mt-20"><span className="font-xs color-gray-500 font-medium">Have not an account?</span><a className="font-xs color-brand-3 font-medium" href="page-register.html">Sign Up</a></div>
              </div>
            </Col>
            <Col lg={5}></Col>
          </Row>
        </Container>
      </section>
      <section className="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6} md={7} sm={12}>
              <h3 className="color-white">Subscrible &amp; Get <span className="color-warning">10%</span> Discount</h3>
              <p className="font-lg color-white">Get E-mail updates about our latest shop and <span className="font-lg-bold">special offers.</span></p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div className="box-form-newsletter mt-15">
                <form className="form-newsletter">
                  <input className="input-newsletter font-xs" value="" placeholder="Your email Address"/>
                  <button className="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
            <Footer/>
        </>
    );
}
export default Pagelogin;