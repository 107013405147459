import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import gpay from "../../assets/imgs/page/checkout/gpay.svg";
import paypal from "../../assets/imgs/page/checkout/paypal.svg";
import amazon from "../../assets/imgs/page/checkout/amazon.svg";
import imgsub from "../../assets/imgs/page/product/img-sub.png";
import star from "../../assets/imgs/template/icons/star.svg";
import stargray from "../../assets/imgs/template/icons/star-gray.svg";
import imgsub2 from "../../assets/imgs/page/product/img-sub2.png";
import imgsub3 from "../../assets/imgs/page/product/img-sub3.png";
import delivery from "../../assets/imgs/template/delivery.svg";
import support from "../../assets/imgs/template/support.svg";
import voucher from "../../assets/imgs/template/voucher.svg";
import returned from "../../assets/imgs/template/return.svg";
import secure from "../../assets/imgs/template/secure.svg";

function Shopcheckout(){
    return(
        <>
            <Header/>
            <Sidebar/>

      <div className="section-box">
        <div className="breadcrumbs-div">
          <Container>
            <ul className="breadcrumb">
              <li><a className="font-xs color-gray-1000" href="index.html">Home</a></li>
              <li><a className="font-xs color-gray-500" href="shop-grid.html">Shop</a></li>
              <li><a className="font-xs color-gray-500" href="shop-cart.html">Checkout</a></li>
            </ul>
          </Container>
        </div>
      </div>
      <section className="section-box shop-template">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="box-border">
                <div className="box-payment"><a className="btn btn-gpay"><img src={gpay} alt="Ecom"/></a><a className="btn btn-paypal"><img src={paypal} alt="Ecom"/></a><a className="btn btn-amazon"><img src={amazon} alt="Ecom"/></a></div>
                <div className="border-bottom-4 text-center mb-20">
                  <div className="text-or font-md color-gray-500">Or</div>
                </div>
                <Row>
                  <Col lg={6} sm={6} className="mb-20">
                    <h5 className="font-md-bold color-brand-3 text-sm-start text-center">Contact information</h5>
                  </Col>
                  <Col lg={6} sm={6} className="mb-20 text-sm-end text-center"><span className="font-sm color-brand-3">Already have an account?</span><a className="font-sm color-brand-1" href="page-login.html"> Login</a></Col>
                  <Col lg={12}>
                    <div className="form-group">
                      <input className="form-control font-sm" type="text" placeholder="Email*"/>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group">
                      <label className="font-sm color-brand-3" for="checkboxOffers">
                        <input className="checkboxOffer" id="checkboxOffers" type="checkbox"/>Keep me up to date on news and exclusive offers
                      </label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <h5 className="font-md-bold color-brand-3 mt-15 mb-20">Shipping address</h5>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group">
                      <input className="form-control font-sm" type="text" placeholder="First name*"/>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group">
                      <input className="form-control font-sm" type="text" placeholder="Last name*"/>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group">
                      <input className="form-control font-sm" type="text" placeholder="Address 1*"/>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group">
                      <input className="form-control font-sm" type="text" placeholder="Address 2*"/>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group">
                      <select className="form-control font-sm select-style1 color-gray-700">
                        <option value="">Select an option...</option>
                        <option value="1">Option 1</option>
                      </select>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group">
                      <input className="form-control font-sm" type="text" placeholder="City*"/>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group">
                      <input className="form-control font-sm" type="text" placeholder="PostCode / ZIP*"/>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group">
                      <input className="form-control font-sm" type="text" placeholder="Company name"/>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group">
                      <input className="form-control font-sm" type="text" placeholder="Phone*"/>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group mb-0">
                      <textarea className="form-control font-sm" placeholder="Additional Information" rows="5"></textarea>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row className="mt-20">
                <Col lg={6} md={5} className="mb-20"><a className="btn font-sm-bold color-brand-1 arrow-back-1" href="shop-cart.html">Return to Cart</a></Col>
                <Col lg={6} md={7} className="mb-20 text-end"><a className="btn btn-buy w-auto arrow-next" href="shop-checkout.html">Place an Order</a></Col>
              </Row>
            </Col>
            <Col lg={6}>
              <div className="box-border">
                <h5 className="font-md-bold mb-20">Your Order</h5>
                <div className="listCheckout">
                  <div className="item-wishlist">
                    <div className="wishlist-product">
                      <div className="product-wishlist">
                        <div className="product-image"><a href="shop-single-product.html"><img src={imgsub} alt="Ecom"/></a></div>
                        <div className="product-info"><a href="shop-single-product.html">
                            <h6 className="color-brand-3">Gateway 23.8&quot; All-in-one Desktop, Fully Adjustable Stand</h6></a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                        </div>
                      </div>
                    </div>
                    <div className="wishlist-status">
                      <h5 className="color-gray-500">x1</h5>
                    </div>
                    <div className="wishlist-price">
                      <h4 className="color-brand-3 font-lg-bold">$2.51</h4>
                    </div>
                  </div>
                  <div className="item-wishlist">
                    <div className="wishlist-product">
                      <div className="product-wishlist">
                        <div className="product-image"><a href="shop-single-product.html"><img src={imgsub2} alt="Ecom"/></a></div>
                        <div className="product-info"><a href="shop-single-product.html">
                            <h6 className="color-brand-3">HP 24 All-in-One PC, Intel Core i3-1115G4, 4GB RAM</h6></a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                        </div>
                      </div>
                    </div>
                    <div className="wishlist-status">
                      <h5 className="color-gray-500">x1</h5>
                    </div>
                    <div className="wishlist-price">
                      <h4 className="color-brand-3 font-lg-bold">$1.51</h4>
                    </div>
                  </div>
                  <div className="item-wishlist">
                    <div className="wishlist-product">
                      <div className="product-wishlist">
                        <div className="product-image"><a href="shop-single-product.html"><img src={imgsub3} alt="Ecom"/></a></div>
                        <div className="product-info"><a href="shop-single-product.html">
                            <h6 className="color-brand-3">Dell Optiplex 9020 Small Form Business Desktop Tower PC</h6></a>
                          <div className="rating"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="font-xs color-gray-500"> (65)</span></div>
                        </div>
                      </div>
                    </div>
                    <div className="wishlist-status">
                      <h5 className="color-gray-500">x1</h5>
                    </div>
                    <div className="wishlist-price">
                      <h4 className="color-brand-3 font-lg-bold">$3.51</h4>
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex mt-15">
                  <input className="form-control mr-15" placeholder="Enter Your Coupon"/>
                  <button className="btn btn-buy w-auto">Apply</button>
                </div>
                <div className="form-group mb-0">
                  <div className="row mb-10">
                    <div className="col-lg-6 col-6"><span className="font-md-bold color-brand-3">Subtotal</span></div>
                    <div className="col-lg-6 col-6 text-end"><span className="font-lg-bold color-brand-3">$6.51</span></div>
                  </div>
                  <div className="border-bottom mb-10 pb-5">
                    <div className="row">
                      <div className="col-lg-6 col-6"><span className="font-md-bold color-brand-3">Shipping</span></div>
                      <div className="col-lg-6 col-6 text-end"><span className="font-lg-bold color-brand-3">-</span></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-6"><span className="font-md-bold color-brand-3">Total</span></div>
                    <div className="col-lg-6 col-6 text-end"><span className="font-lg-bold color-brand-3">$6.51</span></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-box mt-90 mb-50">
        <Container>
          <ul className="list-col-5">
            <li>
              <div className="item-list">
                <div className="icon-left"><img src={delivery} alt="Ecom"/></div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Free Delivery</h5>
                  <p className="font-sm color-gray-500">From all orders over $10</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left"><img src={support} alt="Ecom"/></div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Support 24/7</h5>
                  <p className="font-sm color-gray-500">Shop with an expert</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left"><img src={voucher} alt="Ecom"/></div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Gift voucher</h5>
                  <p className="font-sm color-gray-500">Refer a friend</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left"><img src={returned} alt="Ecom"/></div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Return &amp; Refund</h5>
                  <p className="font-sm color-gray-500">Free return over $200</p>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left"><img src={secure} alt="Ecom"/></div>
                <div className="info-right">
                  <h5 className="font-lg-bold color-gray-100">Secure payment</h5>
                  <p className="font-sm color-gray-500">100% Protected</p>
                </div>
              </div>
            </li>
          </ul>
        </Container>
      </section>
      <section className="section-box box-newsletter">
        <Container>
          <Row>
            <Col lg={6} md={7} sm={12}>
              <h3 className="color-white">Subscrible &amp; Get <span className="color-warning">10%</span> Discount</h3>
              <p className="font-lg color-white">Get E-mail updates about our latest shop and <span className="font-lg-bold">special offers.</span></p>
            </Col>
            <Col lg={4} md={5} sm={12}>
              <div className="box-form-newsletter mt-15">
                <form className="form-newsletter">
                  <input className="input-newsletter font-xs" value="" placeholder="Your email Address"/>
                  <button className="btn btn-brand-2">Sign Up</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="modal fade" id="ModalFiltersForm" tabindex="-1" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content apply-job-form">
            <div className="modal-header">
              <h5 className="modal-title color-gray-1000 filters-icon">Addvance Fillters</h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body p-30">
              <Row>
                <div className="col-w-1">
                  <h6 className="color-gray-900 mb-0">Brands</h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked"/><span className="text-small">Apple</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Samsung</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Baseus</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Remax</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Handtown</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Elecom</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Razer</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Auto Focus</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Nillkin</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Logitech</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">ChromeBook</span><span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="col-w-1">
                  <h6 className="color-gray-900 mb-0">Special offers</h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">On sale</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked"/><span className="text-small">FREE shipping</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Big deals</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Shop Mall</span><span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <h6 className="color-gray-900 mb-0 mt-40">Ready to ship in</h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">1 business day</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked"/><span className="text-small">1&ndash;3 business days</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">in 1 week</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Shipping now</span><span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="col-w-1">
                  <h6 className="color-gray-900 mb-0">Ordering options</h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Accepts gift cards</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Customizable</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked"/><span className="text-small">Can be gift-wrapped</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Installment 0%</span><span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <h6 className="color-gray-900 mb-0 mt-40">Rating</h6>
                  <ul className="list-checkbox">
                    <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(5 stars)</span></a></li>
                    <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={stargray} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(4 stars)</span></a></li>
                    <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(3 stars)</span></a></li>
                    <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={star} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(2 stars)</span></a></li>
                    <li className="mb-5"><a href="#"><img src={star} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><img src={stargray} alt="Ecom"/><span className="ml-10 font-xs color-gray-500 d-inline-block align-top">(1 star)</span></a></li>
                  </ul>
                </div>
                <div className="col-w-2">
                  <h6 className="color-gray-900 mb-0">Material</h6>
                  <ul className="list-checkbox">
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Nylon (8)</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Tempered Glass (5)</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox" checked="checked"/><span className="text-small">Liquid Silicone Rubber (5)</span><span className="checkmark"></span>
                      </label>
                    </li>
                    <li>
                      <label className="cb-container">
                        <input type="checkbox"/><span className="text-small">Aluminium Alloy (3)</span><span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                  <h6 className="color-gray-900 mb-20 mt-40">Product tags</h6>
                  <div><a className="btn btn-border mr-5" href="#">Games</a><a className="btn btn-border mr-5" href="#">Electronics</a><a className="btn btn-border mr-5" href="#">Video</a><a className="btn btn-border mr-5" href="#">Cellphone</a><a className="btn btn-border mr-5" href="#">Indoor</a><a className="btn btn-border mr-5" href="#">VGA Card</a><a className="btn btn-border mr-5" href="#">USB</a><a className="btn btn-border mr-5" href="#">Lightning</a><a className="btn btn-border mr-5" href="#">Camera</a></div>
                </div>
              </Row>
            </div>
            <div className="modal-footer justify-content-start pl-30"><a className="btn btn-buy w-auto" href="#">Apply Fillter</a><a className="btn font-sm-bold color-gray-500" href="#">Reset Fillter</a></div>
          </div>
        </div>
      </div>
            
            <Footer/>
        </>
    );
}
export default Shopcheckout;