import logo from "../../src/assets/imgs/template/logo.svg";
import en from "../../src/assets/imgs/template/en.svg";
import armchair from "../../src/assets/imgs/page/homepage6/armchair.svg";
import bathhub from "../../src/assets/imgs/page/homepage6/bathhub.svg";
import bed from "../../src/assets/imgs/page/homepage6/bed.svg";
import office from "../../src/assets/imgs/page/homepage6/office.svg";
import chair from "../../src/assets/imgs/page/homepage6/chair.svg";
import chest from "../../src/assets/imgs/page/homepage6/chest.svg";
import dressing from "../../src/assets/imgs/page/homepage6/dressing.svg";
import lamp from "../../src/assets/imgs/page/homepage6/lamp.svg";
import sofa from "../../src/assets/imgs/page/homepage6/sofa.svg";
import storage from "../../src/assets/imgs/page/homepage6/storage.svg";
import electric from "../../src/assets/imgs/page/homepage6/electric.svg";
import wardrobe from "../../src/assets/imgs/page/homepage6/wardrobe.svg";

function Sidebar(){
    return(
        <>
             <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-content-area">
          <div className="mobile-logo"><a className="d-flex" href="index.html"><img alt="Ecom" src="assets/imgs/template/logo.svg"/></a></div>
          <div className="perfect-scroll">
            <div className="mobile-menu-wrap mobile-header-border">
              <nav className="mt-15">
                <ul className="mobile-menu font-heading">
                  <li className="has-children"><a className="active" href="index.html">Home</a>
                    <ul className="sub-menu">
                      <li><a href="index.html">Homepage - 1</a></li>
                      <li><a href="index-2.html">Homepage - 2</a></li>
                      <li><a href="index-3.html">Homepage - 3</a></li>
                      <li><a href="index-4.html">Homepage - 4</a></li>
                      <li><a href="index-5.html">Homepage - 5</a></li>
                      <li><a href="index-6.html">Homepage - 6</a></li>
                      <li><a href="index-7.html">Homepage - 7</a></li>
                      <li><a href="index-8.html">Homepage - 8</a></li>
                      <li><a href="index-9.html">Homepage - 9</a></li>
                      <li><a href="index-10.html">Homepage - 10</a></li>
                    </ul>
                  </li>
                  <li className="has-children"><a href="shop-grid.html">Shop</a>
                    <ul className="sub-menu">
                      <li><a href="shop-grid.html">Shop Grid</a></li>
                      <li><a href="shop-grid-2.html">Shop Grid 2</a></li>
                      <li><a href="shop-list.html">Shop List</a></li>
                      <li><a href="shop-list-2.html">Shop List 2</a></li>
                      <li><a href="shop-fullwidth.html">Shop Fullwidth</a></li>
                      <li><a href="shop-single-product.html">Single Product</a></li>
                      <li><a href="shop-single-product-2.html">Single Product 2</a></li>
                      <li><a href="shop-single-product-3.html">Single Product 3</a></li>
                      <li><a href="shop-single-product-4.html">Single Product 4</a></li>
                      <li><a href="shop-cart.html">Shop Cart</a></li>
                      <li><a href="shop-checkout.html">Shop Checkout</a></li>
                      <li><a href="shop-compare.html">Shop Compare</a></li>
                      <li><a href="shop-wishlist.html">Shop Wishlist</a></li>
                    </ul>
                  </li>
                  <li className="has-children"><a href="shop-vendor-list.html">Vendors</a>
                    <ul className="sub-menu">
                      <li><a href="shop-vendor-list.html">Vendors Listing</a></li>
                      <li><a href="shop-vendor-single.html">Vendor Single</a></li>
                    </ul>
                  </li>
                  <li className="has-children"><a href="#">Pages</a>
                    <ul className="sub-menu">
                      <li><a href="page-about-us.html">About Us</a></li>
                      <li><a href="page-contact.html">Contact Us</a></li>
                      <li><a href="page-careers.html">Careers</a></li>
                      <li><a href="page-term.html">Term and Condition</a></li>
                      <li><a href="page-register.html">Register</a></li>
                      <li><a href="page-login.html">Login</a></li>
                      <li><a href="page-404.html">Error 404</a></li>
                    </ul>
                  </li>
                  <li className="has-children"><a href="blog.html">Blog</a>
                    <ul className="sub-menu">
                      <li><a href="blog.html">Blog Grid</a></li>
                      <li><a href="blog-2.html">Blog Grid 2</a></li>
                      <li><a href="blog-list.html">Blog List</a></li>
                      <li><a href="blog-big.html">Blog Big</a></li>
                      <li><a href="blog-single.html">Blog Single - Left sidebar</a></li>
                      <li><a href="blog-single-2.html">Blog Single - Right sidebar</a></li>
                      <li><a href="blog-single-3.html">Blog Single - No sidebar</a></li>
                    </ul>
                  </li>
                  <li><a href="page-contact.html">Contact</a></li>
                </ul>
              </nav>
            </div>
            <div className="mobile-account">
              <div className="mobile-header-top">
                <div className="user-account"><a href="page-account.html"><img src="assets/imgs/template/ava_1.png" alt="Ecom"/></a>
                  <div className="content">
                    <h6 className="user-name">Hello<span className="text-brand"> Steven !</span></h6>
                    <p className="font-xs text-muted">You have 3 new messages</p>
                  </div>
                </div>
              </div>
              <ul className="mobile-menu">
                <li><a href="page-account.html">My Account</a></li>
                <li><a href="page-account.html">Order Tracking</a></li>
                <li><a href="page-account.html">My Orders</a></li>
                <li><a href="page-account.html">My Wishlist</a></li>
                <li><a href="page-account.html">Setting</a></li>
                <li><a href="page-login.html">Sign out</a></li>
              </ul>
            </div>
            <div className="mobile-banner">
              <div className="bg-5 block-iphone"><span className="color-brand-3 font-sm-lh32">Starting from $899</span>
                <h3 className="font-xl mb-10">iPhone 12 Pro 128Gb</h3>
                <p className="font-base color-brand-3 mb-10">Special Sale</p><a className="btn btn-arrow" href="shop-grid.html">learn more</a>
              </div>
            </div>
            <div className="site-copyright color-gray-400 mt-30">Copyright 2022 &copy; Ecom - Marketplace Template.<br/>Designed by<a href="http://alithemes.com" target="_blank">&nbsp; AliThemes</a></div>
          </div>
        </div>
      </div>
    </div>
    <div className="sidebar-left"><a className="btn btn-open" href="#"></a>
      <ul className="menu-icons hidden">
        <li><a href="javascript:void(0)"><img src={armchair} alt="Ecom"/></a></li>
        <li><a href="javascript:void(0)"><img src={bathhub} alt="Ecom"/></a></li>
        <li><a href="#"><img src={bed} alt="Ecom"/></a></li>
        <li><a href="#"><img src={office} alt="Ecom"/></a></li>
        <li><a href="#"><img src={chair} alt="Ecom"/></a></li>
        <li><a href="#"><img src={chest} alt="Ecom"/></a></li>
        <li><a href="#"><img src={dressing} alt="Ecom"/></a></li>
        <li><a href="#"><img src={lamp} alt="Ecom"/></a></li>
        <li><a href="#"><img src={sofa} alt="Ecom"/></a></li>
        <li><a href="#"><img src={storage} alt="Ecom"/></a></li>
        <li><a href="#"><img src={electric} alt="Ecom"/></a></li>
        <li><a href="#"><img src={wardrobe} alt="Ecom"/></a></li>
      </ul>
      <ul className="menu-texts menu-close">
        <li className="has-children"><a href="shop-grid-2.html"><span className="img-link"><img src={armchair} alt="Ecom"/></span><span className="text-link">Armchair</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Cross Back</a></li>
            <li><a href="shop-grid.html">Open Back</a></li>
            <li><a href="shop-grid.html">Queen Anne Back</a></li>
            <li><a href="shop-grid.html">Solid Back</a></li>
            <li><a href="shop-grid.html">Wing Back</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="#"><span className="img-link"><img src={bathhub} alt="Ecom"/></span><span className="text-link">Bathtub</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Freestanding Bathtubs</a></li>
            <li><a href="shop-grid.html">Drop-In Bathtubs</a></li>
            <li><a href="shop-grid.html">Recessed Bathtubs</a></li>
            <li><a href="shop-grid.html">Faucets & Showerheads</a></li>
            <li><a href="shop-grid.html">Baby Bath Tubs</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={bed} alt="Ecom"/></span><span className="text-link">Bed</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Bamboo, Wood</a></li>
            <li><a href="shop-grid.html">Fabric, Leather</a></li>
            <li><a href="shop-grid.html">Glass, Plastic</a></li>
            <li><a href="shop-grid.html">Leather, Metal</a></li>
            <li><a href="shop-grid.html">Manufactured Wood</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={office} alt="Ecom"/></span><span className="text-link">Office Cabinet</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Chair</a></li>
            <li><a href="shop-grid.html">Chest</a></li>
            <li><a href="shop-grid.html">Cross Back</a></li>
            <li><a href="shop-grid.html">Open Back</a></li>
            <li><a href="shop-grid.html">Queen Anne Back</a></li>
            <li><a href="shop-grid.html">Solid Back</a></li>
            <li><a href="shop-grid.html">Wing Back</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={chair} alt="Ecom"/></span><span className="text-link">Chair</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Home Office Desk</a></li>
            <li><a href="shop-grid.html">Living Room</a></li>
            <li><a href="shop-grid.html">Computer Gaming</a></li>
            <li><a href="shop-grid.html">Kitchen & Dining Room</a></li>
            <li><a href="shop-grid.html">Office  & Sofas</a></li>
            <li><a href="shop-grid.html">Managerial  & Executive</a></li>
            <li><a href="shop-grid.html">Video Game</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={chest} alt="Ecom"/></span><span className="text-link">Chest</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Chests of Drawers</a></li>
            <li><a href="shop-grid.html">Ottomans</a></li>
            <li><a href="shop-grid.html">Storage Benches</a></li>
            <li><a href="shop-grid.html">Decorative Boxes</a></li>
            <li><a href="shop-grid.html">Storage Chests</a></li>
            <li><a href="shop-grid.html">Storage Trunks</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={dressing} alt="Ecom"/></span><span className="text-link">Dressing Table</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Antique</a></li>
            <li><a href="shop-grid.html">Casual</a></li>
            <li><a href="shop-grid.html">Chinese</a></li>
            <li><a href="shop-grid.html">classNameic</a></li>
            <li><a href="shop-grid.html">Colonial</a></li>
            <li><a href="shop-grid.html">Contemporary</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={lamp} alt="Ecom"/></span><span className="text-link">Lamp</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Desk Lamps</a></li>
            <li><a href="shop-grid.html">Floor Lamps</a></li>
            <li><a href="shop-grid.html">Table Lamps</a></li>
            <li><a href="shop-grid.html">Lamp Sets</a></li>
            <li><a href="shop-grid.html">Colonial</a></li>
            <li><a href="shop-grid.html">Lamp Shades</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={sofa} alt="Ecom"/></span><span className="text-link">Sofa</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Zinus</a></li>
            <li><a href="shop-grid.html">Lifestyle Solutions</a></li>
            <li><a href="shop-grid.html">Generic</a></li>
            <li><a href="shop-grid.html">Rivet</a></li>
            <li><a href="shop-grid.html">Design by Ashley</a></li>
            <li><a href="shop-grid.html">Knight Home</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={storage} alt="Ecom"/></span><span className="text-link">Storage</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Convertible</a></li>
            <li><a href="shop-grid.html">Divan</a></li>
            <li><a href="shop-grid.html">Futon</a></li>
            <li><a href="shop-grid.html">Loveseat</a></li>
            <li><a href="shop-grid.html">Recliner</a></li>
            <li><a href="shop-grid.html">Standard</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={electric} alt="Ecom"/></span><span className="text-link">Electric Table</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">Modern</a></li>
            <li><a href="shop-grid.html">Contemporary</a></li>
            <li><a href="shop-grid.html">Armless</a></li>
            <li><a href="shop-grid.html">Pillow-top</a></li>
            <li><a href="shop-grid.html">Rolled-panel</a></li>
            <li><a href="shop-grid.html">Leather</a></li>
          </ul>
        </li>
        <li className="has-children"><a href="shop-grid.html"><span className="img-link"><img src={wardrobe} alt="Ecom"/></span><span className="text-link">Wardrobe</span></a>
          <ul className="sub-menu">
            <li><a href="shop-grid.html">ClosetMaid</a></li>
            <li><a href="shop-grid.html">South Shore</a></li>
            <li><a href="shop-grid.html">Sauder</a></li>
            <li><a href="shop-grid.html">Bush Furniture</a></li>
            <li><a href="shop-grid.html">Walker Edison</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-content-area">
          <div className="mobile-logo"><a className="d-flex" href="index.html"><img alt="Ecom" src={logo}/></a></div>
          <div className="perfect-scroll">
            <div className="mobile-menu-wrap mobile-header-border">
              <nav className="mt-15">
                <ul className="mobile-menu font-heading">
                  <li className="has-children"><a className="active" href="index.html">Home</a>
                    <ul className="sub-menu">
                      <li><a href="index.html">Homepage - 1</a></li>
                      <li><a href="index-2.html">Homepage - 2</a></li>
                      <li><a href="index-3.html">Homepage - 3</a></li>
                      <li><a href="index-4.html">Homepage - 4</a></li>
                      <li><a href="index-5.html">Homepage - 5</a></li>
                      <li><a href="index-6.html">Homepage - 6</a></li>
                      <li><a href="index-7.html">Homepage - 7</a></li>
                      <li><a href="index-8.html">Homepage - 8</a></li>
                      <li><a href="index-9.html">Homepage - 9</a></li>
                      <li><a href="index-10.html">Homepage - 10</a></li>
                    </ul>
                  </li>
                  <li className="has-children"><a href="shop-grid.html">Shop</a>
                    <ul className="sub-menu">
                      <li><a href="shop-grid.html">Shop Grid</a></li>
                      <li><a href="shop-grid-2.html">Shop Grid 2</a></li>
                      <li><a href="shop-list.html">Shop List</a></li>
                      <li><a href="shop-list-2.html">Shop List 2</a></li>
                      <li><a href="shop-fullwidth.html">Shop Fullwidth</a></li>
                      <li><a href="shop-single-product.html">Single Product</a></li>
                      <li><a href="shop-single-product-2.html">Single Product 2</a></li>
                      <li><a href="shop-single-product-3.html">Single Product 3</a></li>
                      <li><a href="shop-single-product-4.html">Single Product 4</a></li>
                      <li><a href="shop-cart.html">Shop Cart</a></li>
                      <li><a href="shop-checkout.html">Shop Checkout</a></li>
                      <li><a href="shop-compare.html">Shop Compare</a></li>
                      <li><a href="shop-wishlist.html">Shop Wishlist</a></li>
                    </ul>
                  </li>
                  <li className="has-children"><a href="shop-vendor-list.html">Vendors</a>
                    <ul className="sub-menu">
                      <li><a href="shop-vendor-list.html">Vendors Listing</a></li>
                      <li><a href="shop-vendor-single.html">Vendor Single</a></li>
                    </ul>
                  </li>
                  <li className="has-children"><a href="#">Pages</a>
                    <ul className="sub-menu">
                      <li><a href="page-about-us.html">About Us</a></li>
                      <li><a href="page-contact.html">Contact Us</a></li>
                      <li><a href="page-careers.html">Careers</a></li>
                      <li><a href="page-term.html">Term and Condition</a></li>
                      <li><a href="page-register.html">Register</a></li>
                      <li><a href="page-login.html">Login</a></li>
                      <li><a href="page-404.html">Error 404</a></li>
                    </ul>
                  </li>
                  <li className="has-children"><a href="blog.html">Blog</a>
                    <ul className="sub-menu">
                      <li><a href="blog.html">Blog Grid</a></li>
                      <li><a href="blog-2.html">Blog Grid 2</a></li>
                      <li><a href="blog-list.html">Blog List</a></li>
                      <li><a href="blog-big.html">Blog Big</a></li>
                      <li><a href="blog-single.html">Blog Single - Left sidebar</a></li>
                      <li><a href="blog-single-2.html">Blog Single - Right sidebar</a></li>
                      <li><a href="blog-single-3.html">Blog Single - No sidebar</a></li>
                    </ul>
                  </li>
                  <li><a href="page-contact.html">Contact</a></li>
                </ul>
              </nav>
            </div>
            <div className="mobile-account">
              <div className="mobile-header-top">
                <div className="user-account"><a href="page-account.html"><img src="assets/imgs/template/ava_1.png" alt="Ecom"/></a>
                  <div className="content">
                    <h6 className="user-name">Hello<span className="text-brand"> Steven !</span></h6>
                    <p className="font-xs text-muted">You have 3 new messages</p>
                  </div>
                </div>
              </div>
              <ul className="mobile-menu">
                <li><a href="page-account.html">My Account</a></li>
                <li><a href="page-account.html">Order Tracking</a></li>
                <li><a href="page-account.html">My Orders</a></li>
                <li><a href="page-account.html">My Wishlist</a></li>
                <li><a href="page-account.html">Setting</a></li>
                <li><a href="page-login.html">Sign out</a></li>
              </ul>
            </div>
            <div className="mobile-banner">
              <div className="bg-5 block-iphone"><span className="color-brand-3 font-sm-lh32">Starting from $899</span>
                <h3 className="font-xl mb-10">iPhone 12 Pro 128Gb</h3>
                <p className="font-base color-brand-3 mb-10">Special Sale</p><a className="btn btn-arrow" href="shop-grid.html">learn more</a>
              </div>
            </div>
            <div className="site-copyright color-gray-400 mt-30">Copyright 2022 &copy; Ecom - Marketplace Template.<br/>Designed by<a href="http://alithemes.com" target="_blank">&nbsp; AliThemes</a></div>
          </div>
        </div>
      </div>
    </div>
        </>
    );
}
export default Sidebar;